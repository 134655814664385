import { Map } from '../types';

export default [
  {
    id: 'de_dust2',
    map: 'de_dust2',
    name: 'Dust 2',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_inferno',
    map: 'de_inferno',
    name: 'Inferno',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_inferno_old',
    map: 'de_inferno_old',
    name: 'Inferno Old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_mirage',
    map: 'de_mirage',
    name: 'Mirage',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_nuke',
    map: 'de_nuke',
    name: 'Nuke',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_nuke_old',
    map: 'de_nuke_old',
    name: 'Nuke Old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_overpass',
    map: 'de_overpass',
    name: 'Overpass',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_train',
    map: 'de_train',
    name: 'Train',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_train_old',
    map: 'de_train_old',
    name: 'Train old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_vertigo',
    map: 'de_vertigo',
    name: 'Vertigo',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_cache',
    map: 'de_cache',
    name: 'Cache',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_cache_old',
    map: 'de_cache_old',
    name: 'Cache Old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_cbble_classic',
    map: 'de_cbble_classic',
    name: 'Cobblestone Old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'aim_ak-colt',
    map: 'aim_ak-colt',
    name: 'Aim Ak Colt',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'awp_india',
    map: 'awp_india',
    name: 'AWP India',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'fy_pool_day',
    map: 'fy_pool_day',
    name: 'Pool Day',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_cbble',
    map: 'de_cbble',
    name: 'Cobblestone',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_aztec',
    map: 'de_aztec',
    name: 'Aztec',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'cs_assault',
    map: 'cs_assault',
    name: 'Assault',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'cs_office',
    map: 'cs_office',
    name: 'Office',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_ancient',
    map: 'de_ancient',
    name: 'Ancient',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_bank',
    map: 'de_bank',
    name: 'Bank',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_lake',
    map: 'de_lake',
    name: 'Lake',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_safehouse',
    map: 'de_safehouse',
    name: 'Safe House',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_iris',
    map: 'de_iris',
    name: 'Iris',
    category: 'COMPETITIVE_EXTRA',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'cs_agency',
    map: 'cs_agency',
    name: 'Agency',
    category: 'COMPETITIVE_EXTRA',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'cs_climb',
    map: 'cs_climb',
    name: 'Climb',
    category: 'COMPETITIVE_EXTRA',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'cs_italy',
    map: 'cs_italy',
    name: 'Italy',
    category: 'COMPETITIVE_EXTRA',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'cs_militia',
    map: 'cs_militia',
    name: 'Militia',
    category: 'COMPETITIVE_EXTRA',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'cs_office',
    map: 'cs_office',
    name: 'Office',
    category: 'COMPETITIVE_EXTRA',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_deathmatch_dust2',
    map: 'de_dust2',
    name: 'DM Dust 2 ',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_inferno',
    map: 'de_inferno',
    name: 'DM Inferno',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_mirage',
    map: 'de_mirage',
    name: 'DM Mirage',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_nuke',
    map: 'de_nuke',
    name: 'DM Nuke',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_overpass',
    map: 'de_overpass',
    name: 'DM Overpass',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_train',
    map: 'de_train',
    name: 'DM Train',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_vertigo',
    map: 'de_vertigo',
    name: 'DM Vertigo',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_cache',
    map: 'de_cache',
    name: 'DM Cache',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'deathmatch_aim_ak-colt',
    map: 'aim_ak-colt',
    name: 'DM Aim Ak Colt',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'deathmatch_awp_india',
    map: 'awp_india',
    name: 'DM AWP India',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'deathmatch_fy_pool_day',
    map: 'fy_pool_day',
    name: 'DM Pool Day',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_cbble',
    map: 'de_cbble',
    name: 'DM Cobblestone',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_aztec',
    map: 'de_aztec',
    name: 'DM Aztec',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'cs_deathmatch_assault',
    map: 'cs_assault',
    name: 'DM Assault',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'cs_deathmatch_office',
    map: 'cs_office',
    name: 'DM Office',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_ancient',
    map: 'de_ancient',
    name: 'DM Ancient',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_bank',
    map: 'de_bank',
    name: 'DM Bank',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_lake',
    map: 'de_lake',
    name: 'DM Lake',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'de_deathmatch_safehouse',
    map: 'de_safehouse',
    name: 'DM Safe House',
    category: 'DEATHMATCH',
    tags: [],
    constraints: {
      playerCount: {
        min: 0,
        max: 5,
      },
    },
    extra: {
      mapType: 'DEATHMATCH',
    },
  },
  {
    id: 'dust2_training_dolnma',
    map: 'dust2_training_dolnma',
    name: 'Dust 2 Smokes e Bangs',
    category: 'TRAINING',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_deathcookin_csgo',
    map: 'de_deathcookin_csgo',
    name: 'Rats Deathcookin',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'surf_ski_2',
    map: 'surf_ski_2',
    name: 'Surf Ski 2 (BETA)',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'he_glass_2016',
    map: 'he_glass_2016',
    name: 'HE Glass',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'cs_rio',
    map: 'cs_rio',
    name: 'CS Rio',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'aim_deagle_bikinibottom',
    map: 'aim_deagle_bikinibottom',
    name: 'Aim Eagle Bikini Bottom',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_bikinibottom',
    map: 'de_bikinibottom',
    name: 'Bikini Bottom',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_not_mirage',
    map: 'de_not_mirage',
    name: 'Not Mirage',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'aim_blast',
    map: 'aim_blast',
    name: 'BLAST Stand-Off',
    category: '3V3',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_inferno_mirror',
    map: 'de_inferno_mirror',
    name: 'Inferno Mirror',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_mirage_mirror',
    map: 'de_mirage_mirror',
    name: 'Mirage Mirror',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_wingman_bank',
    map: 'de_bank',
    name: 'Wingman Bank',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: { mapType: null },
  },
  {
    id: 'de_wingman_lake',
    map: 'de_lake',
    name: 'Wingman Lake',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_safehouse',
    map: 'de_safehouse',
    name: 'Wingman Safe House',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_shortdust',
    map: 'de_shortdust',
    name: 'Short Dust',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_inferno',
    map: 'de_inferno',
    name: 'Short Inferno',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_cbble',
    map: 'de_cbble',
    name: 'Short Cobblestone',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_nuke',
    map: 'de_shortnuke',
    name: 'Short Nuke',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_train',
    map: 'de_train',
    name: 'Short Train',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_vertigo',
    map: 'de_vertigo',
    name: 'Short Vertigo',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_overpass',
    map: 'de_overpass',
    name: 'Short Overpass',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_hive',
    map: 'de_hive',
    name: 'Hive',
    category: 'WINGMAN',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_crete',
    map: 'de_crete',
    name: 'Crete',
    category: 'WINGMAN',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
] as Map[];
