import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

const DISCORD_URL = process.env.REACT_APP_DISCORD_URL;

export default function DiscordContactInfo({ className, noText = false }) {
  const { t } = useTranslation();

  return (
    <a className={className} href={DISCORD_URL}>
      <FontAwesomeIcon icon={faDiscord} />{' '}
      {!noText && t('footer.discordContact')}
    </a>
  );
}
DiscordContactInfo.propTypes = {
  className: string,
};
DiscordContactInfo.defaultProps = {
  className: '',
};
