import React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/qs24.png';

export default () => {
  const { css } = useFela();
  const { t } = useTranslation();

  return (
    <Container>
      <Navbar dark expand='md'>
        <NavbarBrand tag={Link} to='/'>
          <img
            className={css(styles.navbarLogo)}
            src={logo}
            alt={t('navbar.logo.alt')}
          />
        </NavbarBrand>
      </Navbar>
    </Container>
  );
};

const styles = {
  navbarLogo: () => ({
    height: '40px',
  }),
};
