import React from 'react';
import { useFela } from 'react-fela';

import Navbar from '../components/Navbar';
import Footer from '../../components/Footer';

import { useTranslation, Trans } from 'react-i18next';

import { Jumbotron, Container, Row, Col } from 'reactstrap';

export default () => {
  const { css } = useFela();
  const { t } = useTranslation();

  const donationsJumbotron = (
    <Jumbotron style={{ background: 'none' }}>
      <Container>
        <h1 className='display-3'>
          <Trans t={t} i18nKey='donations.title' />
        </h1>
        <p className='lead'>
          <Trans t={t} i18nKey='donations.lead' />
        </p>
        <hr className='my-2' />
        <p>
          <Trans t={t} i18nKey='donations.futureReleases' />
          <span role='img' aria-label='Heart'>
            &#129505;
          </span>
        </p>
      </Container>
    </Jumbotron>
  );

  const picpayQrCode = (
    <img
      src='donate-picpay-qrcode.jpeg'
      className='img-fluid'
      alt='Picpay donations qrcode'
    />
  );

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.homeSection)}>
        <Navbar />
        <Container className='d-flex' style={{ minHeight: '80vh' }}>
          <div className='d-flex align-items-center'>
            <Row>
              <Col sm='4' className='d-flex'>
                <a
                  href='https://picpay.me/alihusseinat'
                  className='d-flex align-items-center'
                >
                  {picpayQrCode}
                </a>
              </Col>
              <Col sm='8'>{donationsJumbotron}</Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className={css(styles.footerSection)}>
        <Footer />
      </div>
    </div>
  );
};

const styles = {
  container: () => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  }),
  footerSection: () => ({
    backgroundColor: '#0b0c10',
    height: 'auto',
    flexGrow: 1,
    color: '#444',
  }),
};
